
import { useShowPontoApoio } from "@/components/PontoApoioView.vue";
import { useConsulta } from "@/composables/crud";
import { PontoApoio, PontoApoioService } from "@/services/PontoApoioService";
import { getService } from "@/services/UserService";
import { useStore } from "@/store";
import { defineComponent, onMounted, ref } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  setup() {
    const router = useRouter();
    const store = useStore();

    const loading = ref(true);
    onMounted(async () => {
      loading.value = false;
    });

    const paView = useShowPontoApoio();
    const {
      data: pontos,
      loading: loadingPa,
      search,
      paginatorInfo,
      consultar,
    } = useConsulta<PontoApoio>(new PontoApoioService());
    search.sortField = "e.cidade";
    search.sortOrder = "ASC";
    search.first = 10;

    return {
      pontos,
      loadingPa,
      search,
      consultar,

      paView,
      loading,
      async changePa(id: number | null) {
        try {
          await getService().changePontoApoioCompra(id);
          // store.commit(
          //   MutationTypes.SET_PERFIL_NOVO,
          //   perfil ? clone(perfil) : null
          // );
          router.push({ name: "loja" });
        } finally {
        }
      },
      async meuPontoApoio() {
        try {
          await getService().changePontoApoioCompraToMe();
          router.push({ name: "loja" });
        } finally {
        }
      },
    };
  },
});
