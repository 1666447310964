<template>
  <div class="p-grid card" v-if="loading">
    <p-progress-spinner />
  </div>
  <div class="p-grid card" v-else>
    <div class="p-col-12 p-md-6 p-md-offset-3 p-text-center">
      <h5>Escolha de onde quer fazer a sua compra:</h5>
    </div>

    <template v-if="$store.state.user?.temPontoApoio">
      <div class="p-col-12 p-md-6 p-md-offset-3 option-card">
        <div class="item-option">
          <span>Comprar para o Meu Ponto de Apoio</span>
          <p-button
            :disabled="!$store.state.user?.pontoApoioCompra"
            label="Selecionar"
            @click="changePa(null)"
          />
        </div>
      </div>
      <div class="p-col-12 p-md-6 p-md-offset-3 option-card">
        <div class="item-option">
          <span>Comprar para o meu usuário</span>
          <p-button
            label="Selecionar"
            @click="meuPontoApoio"
            :disabled="$store.state.user?.pontoApoioCompra"
          />
        </div>
      </div>
    </template>

    <template v-else>
      <div class="p-col-12 p-md-6 p-md-offset-3 option-card">
        <div class="item-option">
          <span>Comprar direto da empresa</span>
          <p-button
            label="Selecionar"
            @click="changePa(null)"
            :disabled="!$store.state.user?.pontoApoioCompra"
          />
        </div>
      </div>

      <div class="p-col-12 p-md-6 p-md-offset-3 option-card p-mt-2">
        <div class="p-mt-1 p-mb-1">
          <p-inputtext
            v-model="search.q"
            @keypress.enter="consultar"
            placeholder="Pesquisar..."
          />
          <p-button icon="pi pi-search" @click="consultar" />
        </div>

        <div v-for="p in pontos" :key="p.id" class="item-option">
          <span>
            <b style="margin-right: 10px">{{ p.apelido }}</b
            ><br />
            {{ p.cidade }} - {{ p.uf }}
          </span>
          <p-button
            label="Selecionar"
            @click="changePa(p.id)"
            :disabled="$store.state.user?.pontoApoioCompra?.id == p.id"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { useShowPontoApoio } from "@/components/PontoApoioView.vue";
import { useConsulta } from "@/composables/crud";
import { PontoApoio, PontoApoioService } from "@/services/PontoApoioService";
import { getService } from "@/services/UserService";
import { useStore } from "@/store";
import { defineComponent, onMounted, ref } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  setup() {
    const router = useRouter();
    const store = useStore();

    const loading = ref(true);
    onMounted(async () => {
      loading.value = false;
    });

    const paView = useShowPontoApoio();
    const {
      data: pontos,
      loading: loadingPa,
      search,
      paginatorInfo,
      consultar,
    } = useConsulta<PontoApoio>(new PontoApoioService());
    search.sortField = "e.cidade";
    search.sortOrder = "ASC";
    search.first = 10;

    return {
      pontos,
      loadingPa,
      search,
      consultar,

      paView,
      loading,
      async changePa(id: number | null) {
        try {
          await getService().changePontoApoioCompra(id);
          // store.commit(
          //   MutationTypes.SET_PERFIL_NOVO,
          //   perfil ? clone(perfil) : null
          // );
          router.push({ name: "loja" });
        } finally {
        }
      },
      async meuPontoApoio() {
        try {
          await getService().changePontoApoioCompraToMe();
          router.push({ name: "loja" });
        } finally {
        }
      },
    };
  },
});
</script>

<style scoped lang="scss">
.option-card {
  border: 2px solid #e9ecef;
  border-radius: 7px;
  // margin: 0.5em;
  padding: 0.7em;
  // cursor: pointer;
  position: relative;
}
.option-card-checked {
  border: 2px solid #007be5;
  background: #eef1f5;
}

.item-option {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin: 10px 0;
  box-sizing: border-box;
  padding: 5px 0;
  border-bottom: 1px solid #dee2e6;
}
.item-option:last-child {
  border-bottom: none;
}
</style>